<template>
  <div>
   <h1>ご注文状況確認</h1>



  
   <div class="statusinfo">
  ※郵送でお申込みのお客さまへ<br><br>

弊社にてお申込書を受領後、注文情報の反映にお時間をいただくため、<br>
当ページにてお申込み状況が表示されない場合がございます。<br>
その場合には、お日にちをあけての検索にご協力をお願いいたします。<br>
12月中旬になりましても確認がとれない場合には、お問い合わせ窓口へご連絡くださいませ。<br>
また検索の際には、依頼主欄に記入されたすべての電話番号でお試しくださいますようお願い申し上げます。
</div>



<div class="statusform">
<dl>
  <dt>
お手紙番号<!--、またはご注文番号：-->
</dt>
  <dd>
    <input type="text" v-model="orderno">
    <p class="info">
※お手紙番号は、SW、SPなどからはじまる番号（例：SW-123456）
<!--<br>※ご注文番号は、数字番号（例：12345678）-->

</p>
  </dd>
</dl>

<dl>
  <dt>
ご依頼主(ご注文者)様の電話番号：	
</dt>
  <dd>
<input type="text" v-model="orderkey">
<p class="info">例：080-1234-5678</p>

  </dd>
</dl>


<button @click="submit">確認する</button>

</div>

<fullloader :loading="fullloading" />


    <dialogalert
      v-model="visible_dialog"
      :title="dialog_title"
      :info="dialog_info"
      :button_ok="dialog_bn_ok"
      :button_cancel="dialog_bn_cancel"
      :ok_fn="dialog_ok_fn"
    />


  </div>
</template>

<script>
import login from '@/components/login';
export default  {
    data:function(){return {
      orderno:"",
      orderkey:"",
      ordertoken:"",
    }},
    props:["hidebar"],
    components:{
        login
    },
    created: function() { this.$parent.middle = 2; 
    console.log("test2",this.hidebar)},
    computed:{
      /*token:function(){
        return this.$store.state.token;
      }*/
    },
    methods:{
      submit:function(){
        if(!this.orderno||!this.orderkey){
         // return;
        }
        //this.fullloading=true;
        this.fetch("getstatus",{orderno:this.orderno,orderkey:this.orderkey}).then((data)=>{
          this.fullloading=false;
          console.log(data)
          if(data.error){
            this.opendialog({title:"ご確認ください",info:data.error,bn_cancel:"OK"});
          }else{
            this.ordertoken=data.ordertoken.value;
            this.opendialog({title:"ご注文が見つかりました。",info:"ご注文状況確認のページへ移動しますか？",
            bn_ok:"移動する",
            bn_cancel:"キャンセル",
            ok_fn:()=>{
              if(!this.ordertoken)return;
              this.$router.push("/cfm/"+data.label+"/status/" + this.ordertoken);}
            });
          }
          console.log(this.ordertoken)
        })
      }
    },
}
</script>

<style scoped lang="scss">
h1{
  margin-top: 1em;
}
.bn_tomypage{
  padding: 3em 0;
}
.statusform{
    border:solid 4px rgb(201, 56, 56);
    padding:2em;
    margin:1em auto;
    max-width: 650px;
    border-radius: .8em;
    .info{
      font-size: .8em;
      padding: 1em;
    }
    padding-top:1em ;
  dl{
  padding: 3em 0;
    border-bottom: solid 1px #ccc;
    dt{
      font-weight: bold;
      color: rgb(90, 90, 90);
      margin-bottom: 1em;
      }
    &:last-child{
      border-bottom: 0;
    }
  }
}
.statusinfo{
  font-size: .9em;
  margin-top: 2em;
}

</style>

<style lang="scss">
#header {
  ul#topmn,
    #spmn,
    .sitemenu{
  display: none !important;
}
.headerinner {
    justify-content: center;
}
}
#footer ul.footmn{
  display: none;
}
#content{
  padding: .3em .5em;
}
.sitelogo{
  pointer-events: none;
}
</style>
